var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { height: "94vh" }, attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "secondary white--text text-h5", attrs: { dark: "" } },
        [
          _vm._v(" Voir un Vin "),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { dark: "", color: "indigo" },
              on: { click: _vm.modifiervin },
            },
            [_vm._v(" Modifier ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { dark: "", color: "indigo" },
              on: { click: _vm.exporter },
            },
            [_vm._v(" Exporter le code QR et les informations du vin ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { dark: "", color: "indigo", to: "/listevins" },
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(" mdi-arrow-left "),
              ]),
              _c("div", [_vm._v("Retour")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 30000, top: "", color: _vm.color, tile: "" },
          model: {
            value: _vm.showResult,
            callback: function ($$v) {
              _vm.showResult = $$v
            },
            expression: "showResult",
          },
        },
        [_vm._v(" " + _vm._s(_vm.result) + " ")]
      ),
      _c(
        "v-card-text",
        {
          staticStyle: {
            "overflow-y": "auto",
            "max-height": "calc(100% - 48px)",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  attrs: { name: "formvin" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c("span", { staticClass: "v-label pa-4" }, [
                              _vm._v("Code QR"),
                            ]),
                            _c("v-img", {
                              staticClass: "align-self-center",
                              attrs: {
                                contain: "",
                                src: _vm.qrcode,
                                "max-width": "256",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c("span", { staticClass: "v-label pa-4" }, [
                              _vm._v("Photo du vin"),
                            ]),
                            _c("v-img", {
                              staticClass: "align-self-center",
                              attrs: {
                                contain: "",
                                src: _vm.photovin,
                                "max-width": "256",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c("span", { staticClass: "v-label pa-4" }, [
                              _vm._v("Photo de l'étiquette"),
                            ]),
                            _c("v-img", {
                              staticClass: "align-self-center",
                              attrs: {
                                contain: "",
                                src: _vm.photoetiquetteface,
                                "max-width": "256",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c("span", { staticClass: "v-label pa-4" }, [
                              _vm._v("Photo de la contre étiquette"),
                            ]),
                            _c("v-img", {
                              staticClass: "align-self-center",
                              attrs: {
                                contain: "",
                                src: _vm.photoetiquettedos,
                                "max-width": "256",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Nom du vin",
                              readonly: "",
                            },
                            model: {
                              value: _vm.nomduvin,
                              callback: function ($$v) {
                                _vm.nomduvin = $$v
                              },
                              expression: "nomduvin",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Numéro de lot",
                              readonly: "",
                            },
                            model: {
                              value: _vm.numerolot,
                              callback: function ($$v) {
                                _vm.numerolot = $$v
                              },
                              expression: "numerolot",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrmillesime,
                              label: "Millésime",
                              readonly: "",
                            },
                            model: {
                              value: _vm.millesime,
                              callback: function ($$v) {
                                _vm.millesime = $$v
                              },
                              expression: "millesime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-combobox", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrappellation,
                              "item-text": (item) => `${item.Appellation}`,
                              "item-value": (item) => `${item.UUID}`,
                              label: "Appellation",
                              readonly: "",
                              "persistent-hint": "",
                              "small-chips": "",
                            },
                            model: {
                              value: _vm.appellation,
                              callback: function ($$v) {
                                _vm.appellation = $$v
                              },
                              expression: "appellation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrcategorie,
                              label: "Catégorie de vin",
                              readonly: "",
                            },
                            model: {
                              value: _vm.categorie,
                              callback: function ($$v) {
                                _vm.categorie = $$v
                              },
                              expression: "categorie",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Couleur",
                              readonly: "",
                            },
                            model: {
                              value: _vm.couleur,
                              callback: function ($$v) {
                                _vm.couleur = $$v
                              },
                              expression: "couleur",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "no-arrow no-border",
                            attrs: {
                              outlined: "",
                              items: _vm.arrconditionnement,
                              "item-text": (item) => `${item.nom}`,
                              "item-value": (item) => `${item.UUID}`,
                              label: "Conditionnement",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.conditionnement,
                              callback: function ($$v) {
                                _vm.conditionnement = $$v
                              },
                              expression: "conditionnement",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              step: "any",
                              label:
                                "TAVA\n                            (Titre alcoométrique volumique acquis)",
                              readonly: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" % vol. ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.tauxdalcool,
                              callback: function ($$v) {
                                _vm.tauxdalcool = $$v
                              },
                              expression: "tauxdalcool",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-combobox", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrcepage,
                              "item-text": (item) => `${item.Cepage}`,
                              "item-value": (item) => `${item.UUID}`,
                              label: "Cépages",
                              multiple: "",
                              chips: "",
                              readonly: "",
                              "persistent-hint": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          typeof item === "object"
                                            ? item.Cepage
                                            : item
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.cepage,
                              callback: function ($$v) {
                                _vm.cepage = $$v
                              },
                              expression: "cepage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h1", [_vm._v("Déclaration nutritionnelle")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              label: "Valeur énergétique Valeurs pour 100 ml",
                              readonly: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" kJ ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.valenerkj,
                              callback: function ($$v) {
                                _vm.valenerkj = $$v
                              },
                              expression: "valenerkj",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              label: "Valeur énergétique Valeurs pour 100 ml",
                              readonly: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" Kcal ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.valenerkcal,
                              callback: function ($$v) {
                                _vm.valenerkcal = $$v
                              },
                              expression: "valenerkcal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              step: "any",
                              label: "Glucides",
                              readonly: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" g ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.glucides,
                              callback: function ($$v) {
                                _vm.glucides = $$v
                              },
                              expression: "glucides",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              type: "number",
                              step: "any",
                              label: "dont sucre résiduel",
                              readonly: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" g ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.sucreresiduel,
                              callback: function ($$v) {
                                _vm.sucreresiduel = $$v
                              },
                              expression: "sucreresiduel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label:
                                "Contient de faibles quantités de matières grasses, d'acides gras saturés, de protéines et de sel.",
                              readonly: "",
                            },
                            model: {
                              value: _vm.checkboxfaiblequant,
                              callback: function ($$v) {
                                _vm.checkboxfaiblequant = $$v
                              },
                              expression: "checkboxfaiblequant",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arringredients,
                              label: "Les ingrédients",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.ingredients,
                              callback: function ($$v) {
                                _vm.ingredients = $$v
                              },
                              expression: "ingredients",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "v-label",
                            staticStyle: { "text-align": "justify" },
                          },
                          [
                            _vm._v(
                              ' Concerne les vins effervescents : L\'adjonction de liqueur de tirage (produit destiné à être ajouté à la cuvée pour provoquer la prise de mousse) et de liqueur d’expédition (produit destiné à être ajouté aux vins mousseux afin de leur conférer des caractéristiques gustatives particulières) peut être indiquée par les mentions spécifiques "liqueur de tirage" et "liqueur d’expédition", seules ou accompagnées d’une liste de leurs composants. '
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrliqueurtirage,
                              label: "Liqueur de tirage",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.liqueurtirage,
                              callback: function ($$v) {
                                _vm.liqueurtirage = $$v
                              },
                              expression: "liqueurtirage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrliqueurexpedition,
                              label: "Liqueur d'expédition",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.liqueurexpedition,
                              callback: function ($$v) {
                                _vm.liqueurexpedition = $$v
                              },
                              expression: "liqueurexpedition",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "v-label",
                            staticStyle: { "text-align": "justify" },
                          },
                          [
                            _vm._v(
                              " Les additifs de la catégorie “régulateurs d’acidité”, similaires ou substituables entre eux, peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum, dont au moins un est présent dans le produit final. "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrregulateursacidite,
                              "item-text": (item) => `${item.nom} ${item.code}`,
                              "item-value": (item) => `${item.nom}`,
                              label: "Régulateurs d'acidité",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.regulateursacidite,
                              callback: function ($$v) {
                                _vm.regulateursacidite = $$v
                              },
                              expression: "regulateursacidite",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrconservateuretantioxydants,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Conservateurs et antioxydants",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.conservateuretantioxydants,
                              callback: function ($$v) {
                                _vm.conservateuretantioxydants = $$v
                              },
                              expression: "conservateuretantioxydants",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrsequestrants,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Séquestrants",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.sequestrants,
                              callback: function ($$v) {
                                _vm.sequestrants = $$v
                              },
                              expression: "sequestrants",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arractivateursfermentation,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label:
                                "Activateurs de fermentation alcoolique et malolactique",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.activateursfermentation,
                              callback: function ($$v) {
                                _vm.activateursfermentation = $$v
                              },
                              expression: "activateursfermentation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arragentsclarifiants,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Agents clarifiants",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.agentsclarifiants,
                              callback: function ($$v) {
                                _vm.agentsclarifiants = $$v
                              },
                              expression: "agentsclarifiants",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "v-label",
                            staticStyle: { "text-align": "justify" },
                          },
                          [
                            _vm._v(
                              ' Les additifs de la catégorie "agents stabilisateurs", similaires ou substituables entre eux, peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum, dont au moins un est présent dans le produit final. '
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arragentsstabilisateurs,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Agents stabilisateurs",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.agentsstabilisateurs,
                              callback: function ($$v) {
                                _vm.agentsstabilisateurs = $$v
                              },
                              expression: "agentsstabilisateurs",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrenzymes,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Enzymes",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.enzymes,
                              callback: function ($$v) {
                                _vm.enzymes = $$v
                              },
                              expression: "enzymes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "v-label",
                            staticStyle: { "text-align": "justify" },
                          },
                          [
                            _vm._v(
                              ' Gaz et gaz d\'emballage, utiliser la mention « Mis en bouteille sous atmosphère protectrice" ou "Peut être mis en bouteille sous atmosphère protectrice" '
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label:
                                "Mis en bouteille sous atmosphère protectrice",
                              readonly: "",
                            },
                            model: {
                              value: _vm.checkboxatmoprotect,
                              callback: function ($$v) {
                                _vm.checkboxatmoprotect = $$v
                              },
                              expression: "checkboxatmoprotect",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label:
                                "Peut être mis en bouteille sous atmosphère protectrice",
                              readonly: "",
                            },
                            model: {
                              value: _vm.checkboxmaybeatmoprotect,
                              callback: function ($$v) {
                                _vm.checkboxmaybeatmoprotect = $$v
                              },
                              expression: "checkboxmaybeatmoprotect",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrgazetgazemballage,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Gaz et gaz d'emballage",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.gazetgazemballage,
                              callback: function ($$v) {
                                _vm.gazetgazemballage = $$v
                              },
                              expression: "gazetgazemballage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arragentsfermentation,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Agents de fermentation",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.agentsfermentation,
                              callback: function ($$v) {
                                _vm.agentsfermentation = $$v
                              },
                              expression: "agentsfermentation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrcorrectiondefauts,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Correction des défauts",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.correctiondefauts,
                              callback: function ($$v) {
                                _vm.correctiondefauts = $$v
                              },
                              expression: "correctiondefauts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.arrautrespratiques,
                              "item-text": _vm.formatItemText,
                              "item-value": (item) => `${item.nom}`,
                              label: "Autres pratiques",
                              readonly: "",
                              multiple: "",
                              chips: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.autrespratiques,
                              callback: function ($$v) {
                                _vm.autrespratiques = $$v
                              },
                              expression: "autrespratiques",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }