<template>
    <v-card flat tile style="height: 94vh;">
        <v-card-title dark class="secondary white--text text-h5">
            Voir un Vin
            <v-btn class="mx-2" dark color="indigo" @click="modifiervin">
                Modifier
            </v-btn>
            <v-spacer />
            <v-btn class="mx-2" dark color="indigo" @click="exporter">
                Exporter le code QR et les informations du vin
            </v-btn>
            <v-spacer />
            <v-btn class="mx-2" dark color="indigo" to="/listevins">
                <v-icon dark>
                    mdi-arrow-left
                </v-icon>
                <div>Retour</div>
            </v-btn>
        </v-card-title>
        <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
            {{ result }}
        </v-snackbar>
        <v-card-text style="overflow-y: auto; max-height: calc(100% - 48px);">
            <v-container>
                <v-form v-model="valid" name="formvin">
                    <v-row>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Code QR</span>
                                <v-img contain :src="qrcode" max-width="256" class="align-self-center" />
                            </div>
                        </v-col>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo du vin</span>
                                <v-img contain :src="photovin" max-width="256" class="align-self-center" />
                            </div>
                        </v-col>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo de l'étiquette</span>
                                <v-img contain :src="photoetiquetteface" max-width="256" class="align-self-center" />
                            </div>
                        </v-col>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo de la contre étiquette</span>
                                <v-img contain :src="photoetiquettedos" max-width="256" class="align-self-center" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="nomduvin" outlined label="Nom du vin" readonly />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="numerolot" outlined label="Numéro de lot" readonly />
                        </v-col>
                        <v-col>
                            <v-select v-model="millesime" outlined :items="arrmillesime" label="Millésime" readonly />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-combobox v-model="appellation" outlined :items="arrappellation"
                                :item-text="item => `${item.Appellation}`" :item-value="item => `${item.UUID}`"
                                label="Appellation" readonly persistent-hint small-chips />
                        </v-col>
                        <v-col>
                            <v-select v-model="categorie" outlined :items="arrcategorie" label="Catégorie de vin"
                                readonly />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="couleur" outlined label="Couleur" readonly />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="conditionnement" outlined :items="arrconditionnement"
                                :item-text="item => `${item.nom}`" :item-value="item => `${item.UUID}`"
                                label="Conditionnement" readonly multiple chips persistent-hint
                                class="no-arrow no-border" />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="tauxdalcool" outlined type="number" step="any" label="TAVA
                                (Titre alcoométrique volumique acquis)" readonly>
                                <template v-slot:append>
                                    % vol.
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-combobox v-model="cepage" outlined :items="arrcepage" :item-text="item => `${item.Cepage}`"
                                :item-value="item => `${item.UUID}`" label="Cépages" multiple chips readonly
                                persistent-hint>
                                <template v-slot:item="{ item }">
                                    {{ typeof item === 'object' ? item.Cepage : item }}
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h1>Déclaration nutritionnelle</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="valenerkj" outlined type="number"
                                label="Valeur énergétique Valeurs pour 100 ml" readonly>
                                <template v-slot:append>
                                    kJ
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="valenerkcal" outlined type="number"
                                label="Valeur énergétique Valeurs pour 100 ml" readonly>
                                <template v-slot:append>
                                    Kcal
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="glucides" outlined type="number" step="any" label="Glucides" readonly>
                                <template v-slot:append>
                                    g
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="sucreresiduel" outlined type="number" step="any"
                                label="dont sucre résiduel" readonly>
                                <template v-slot:append>
                                    g
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox v-model="checkboxfaiblequant"
                                label="Contient de faibles quantités de matières grasses, d'acides gras saturés, de protéines et de sel."
                                readonly />
                        </v-col>
                        <v-col>
                            <v-select v-model="ingredients" outlined :items="arringredients" label="Les ingrédients"
                                readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Concerne les vins effervescents :
                                L'adjonction de liqueur de tirage (produit destiné à être ajouté à la cuvée pour provoquer
                                la prise de mousse) et de liqueur d’expédition (produit destiné à être ajouté aux vins
                                mousseux afin de leur conférer des caractéristiques gustatives particulières) peut être
                                indiquée par les mentions spécifiques "liqueur de tirage" et "liqueur d’expédition", seules
                                ou accompagnées d’une liste de leurs composants.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="liqueurtirage" outlined :items="arrliqueurtirage" label="Liqueur de tirage"
                                readonly multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="liqueurexpedition" outlined :items="arrliqueurexpedition"
                                label="Liqueur d'expédition" readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Les additifs de la catégorie “régulateurs d’acidité”, similaires ou substituables entre eux,
                                peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum,
                                dont au moins un est présent dans le produit
                                final.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="regulateursacidite" outlined :items="arrregulateursacidite"
                                :item-text="item => `${item.nom} ${item.code}`" :item-value="item => `${item.nom}`"
                                label="Régulateurs d'acidité" readonly multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="conservateuretantioxydants" outlined :items="arrconservateuretantioxydants"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Conservateurs et antioxydants" readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="sequestrants" outlined :items="arrsequestrants" :item-text="formatItemText"
                                :item-value="item => `${item.nom}`" label="Séquestrants" readonly multiple chips
                                persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="activateursfermentation" outlined :items="arractivateursfermentation"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Activateurs de fermentation alcoolique et malolactique" readonly multiple chips
                                persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="agentsclarifiants" outlined :items="arragentsclarifiants"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`" label="Agents clarifiants"
                                readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Les additifs de la catégorie "agents stabilisateurs", similaires ou substituables entre eux,
                                peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum,
                                dont au moins un est présent dans le produit final.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="agentsstabilisateurs" outlined :items="arragentsstabilisateurs"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Agents stabilisateurs" readonly multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="enzymes" outlined :items="arrenzymes" :item-text="formatItemText"
                                :item-value="item => `${item.nom}`" label="Enzymes" readonly multiple chips
                                persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Gaz et gaz d'emballage, utiliser la mention « Mis en bouteille sous atmosphère protectrice"
                                ou "Peut être mis en bouteille sous atmosphère protectrice"
                            </div>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="checkboxatmoprotect" label="Mis en bouteille sous atmosphère protectrice"
                                readonly />
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="checkboxmaybeatmoprotect"
                                label="Peut être mis en bouteille sous atmosphère protectrice" readonly />
                        </v-col>
                        <v-col>
                            <v-select v-model="gazetgazemballage" outlined :items="arrgazetgazemballage"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Gaz et gaz d'emballage" readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="agentsfermentation" outlined :items="arragentsfermentation"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Agents de fermentation" readonly multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="correctiondefauts" outlined :items="arrcorrectiondefauts"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Correction des défauts" readonly multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="autrespratiques" outlined :items="arrautrespratiques"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`" label="Autres pratiques"
                                readonly multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import JSZip from 'jszip';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default {
    name: 'VoirVin',
    data: () => ({
        visuetiquettevin: '',
        valid: false,
        showResult: false,
        color: '',
        result: '',
        imgdisplay: null,
        height: 300,
        arringredients: ['Raisins', 'Moût de raisins', 'Sucre (saccharose)', 'Moût de raisins concentré', 'Liqueur de tirage', 'Liqueur d\'expédition'],
        arrliqueurtirage: [],
        arrliqueurexpedition: [],
        arrregulateursacidite: [],
        arrconservateuretantioxydants: [],
        arrsequestrants: [],
        arractivateursfermentation: [],
        arragentsclarifiants: [],
        arragentsstabilisateurs: [],
        arrenzymes: [],
        arrgazetgazemballage: [],
        arragentsfermentation: [],
        arrcorrectiondefauts: [],
        arrautrespratiques: [],
        arrmillesime: [],
        arrappellation: [],
        arrappellationCopy: [],
        arrcepage: [],
        arrcepageCopy: [],
        arrcouleurs: [],
        arrcouleursCopy: [],
        arrconditionnement: [],
        arrcategorie: ['VSIG (vin sans indication géographique) ou VIN DE TABLE', 'IGP (vin avec indication géographique protégée) ou VIN DE PAYS', 'AOP (vin avec appellation d’origine protégée) ou AOC'],
        qrcode: null,
        photovin: null,
        photovintoupload: null,
        photoetiquetteface: null,
        photoetiquettefacetoupload: null,
        photoetiquettedos: null,
        photoetiquettedostoupload: null,
        etiquetteface: '',
        etiquettedos: '',
        millesime: '',
        categorie: '',
        searchTerm: '',
        searchCepage: '',
        appellation: '',
        cepage: '',
        valenerkj: 0,
        valenerkcal: 0,
        glucides: 0,
        sucreresiduel: 0,
        checkboxfaiblequant: false,
        ingredients: '',
        liqueurtirage: '',
        liqueurexpedition: '',
        regulateursacidite: '',
        conservateuretantioxydants: '',
        sequestrants: '',
        activateursfermentation: '',
        agentsclarifiants: '',
        agentsstabilisateurs: '',
        enzymes: '',
        checkboxatmoprotect: '',
        checkboxmaybeatmoprotect: '',
        gazetgazemballage: '',
        agentsfermentation: '',
        correctiondefauts: '',
        autrespratiques: '',
        couleur: '',
        nomduvin: '',
        numerolot: '',
        commentaire: '',
        conditionnement: '',
        tauxdalcool: '',
        image: null,
        imagetoupload: null,
    }),
    async mounted() {
        await this.getappellations();
        await this.getcepages();
        await this.getcouleur();
        await this.getconditionnement();
        this.uuidvin = this.$route.params.uuidvin;
        await this.createyear();
        await this.getenumerations();
        await this.getvin();
    },
    methods: {
        formatItemText(item) {
            let result = '';
            if (item.nom.length > 0) {
                result = `${item.nom}`;
                if (item.code !== undefined) {
                    result += ` ${item.code}`;
                }
                if (item.allergene !== undefined) {
                    result += ` (${item.allergene.toUpperCase()})`;
                }
            }
            return result;
        },
        creerindexappellation() {
            this.appellationarridx = {};
            for (let i = 0; i < this.arrappellation.length; i += 1) {
                this.appellationarridx[this.arrappellation[i].UUID] = this.arrappellation[i].Appellation;
            }
        },
        rechercheappelationParUUID(uuidRecherche) {
            return this.appellationarridx[uuidRecherche] || uuidRecherche;
        },
        creerindexcepage() {
            this.cepagearridx = {};
            for (let i = 0; i < this.arrcepage.length; i += 1) {
                this.cepagearridx[this.arrcepage[i].UUID] = this.arrcepage[i].Cepage;
            }
        },
        recherchecepageParUUID(uuidRecherche) {
            return this.cepagearridx[uuidRecherche] || uuidRecherche;
        },
        createyear() {
            this.arryeartmp = Array.from({ length: 201 }, (v, i) => (i + (new Date().getFullYear() - 200)).toString());
            this.arrmillesime = this.arryeartmp.reverse();
            this.arrmillesime.unshift('Brut Sans Année');
            this.arrmillesime.unshift('Non millésimé');
        },
        async getappellations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallappellations`;
            await vm.$axios.get(url).then((response) => {
                vm.arrappellation = response.data;
                vm.arrappellationCopy = [...vm.arrappellation];
                vm.creerindexappellation();
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcepages() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallcepages`;

            await vm.$axios.get(url).then((response) => {
                vm.arrcepage = response.data;
                vm.arrcepageCopy = [...vm.arrcepage];
                vm.creerindexcepage();
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcouleur() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getcouleur`;
            const response = await vm.$axios.get(url);
            vm.arrcouleurs = response.data.couleur;
            vm.arrcouleursCopy = JSON.parse(JSON.stringify(vm.arrcouleurs));
        },
        async getconditionnement() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getconditionnement`;
            const response = await vm.$axios.get(url);
            vm.arrconditionnement = response.data;
        },
        async getenumerations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getenumerations`;

            let resultats = '';
            const response = await vm.$axios.get(url);
            vm.arrenumerations = response.data;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurtirage');
            vm.arrliqueurtirage = resultats[0].liste.liqueurtirage;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurexpedition');
            vm.arrliqueurexpedition = resultats[0].liste.liqueurexpedition;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'regulateursacidite');
            vm.arrregulateursacidite = resultats[0].liste.regulateursacidite;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'conservateuretantioxydants');
            vm.arrconservateuretantioxydants = resultats[0].liste.conservateuretantioxydants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'sequestrants');
            vm.arrsequestrants = resultats[0].liste.sequestrants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'activateursfermentationalcooliquemalolactique');
            vm.arractivateursfermentation = resultats[0].liste.activateursfermentationalcooliquemalolactique;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsclarifiants');
            vm.arragentsclarifiants = resultats[0].liste.agentsclarifiants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsstabilisateurs');
            vm.arragentsstabilisateurs = resultats[0].liste.agentsstabilisateurs;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'enzymes');
            vm.arrenzymes = resultats[0].liste.enzymes;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'gazetgazemballage');
            vm.arrgazetgazemballage = resultats[0].liste.gazetgazemballage;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsfermentation');
            vm.arragentsfermentation = resultats[0].liste.agentsfermentation;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'correctiondefauts');
            vm.arrcorrectiondefauts = resultats[0].liste.correctiondefauts;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'autrespratiques');
            vm.arrautrespratiques = resultats[0].liste.autrespratiques;
        },
        async getvin() {
            const vm = this;
            const queryscan = this.$route.query.scan;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getvin?cb=${Date.now()}`;

            const bodyFormData = new FormData();
            bodyFormData.append('uuidvin', this.uuidvin);
            bodyFormData.append('queryscan', queryscan);

            const datavin = await vm.$axios.post(url, bodyFormData).then((response) => {
                const rep = response.data[0];
                return rep;
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });

            vm.nomduvin = datavin.nomduvin;
            vm.numerolot = datavin.numerolot;
            vm.millesime = datavin.millesime;
            const searchresponse = vm.rechercheappelationParUUID(datavin.uuid_appellation);
            if (searchresponse !== -1) {
                vm.appellation = searchresponse;
            }
            vm.couleur = datavin.couleur;
            vm.categorie = datavin.categorie;
            vm.conditionnement = datavin.conditionnement.split(',');
            vm.tauxdalcool = datavin.tauxalcool;
            vm.cepage = datavin.cepagesuuids.cepages;
            vm.valenerkj = datavin.valenerkj;
            vm.valenerkcal = datavin.valenerkcal;
            vm.glucides = datavin.glucides;
            vm.sucreresiduel = datavin.sucreresiduel;
            vm.checkboxfaiblequant = datavin.checkboxfaiblequant;
            vm.ingredients = datavin.ingredients.split(',');
            vm.liqueurtirage = datavin.liqueurtirage.split(',');
            vm.liqueurexpedition = datavin.liqueurexpedition.split(',');
            vm.regulateursacidite = datavin.regulateursacidite.split(',');
            vm.conservateuretantioxydants = datavin.conservateuretantioxydants.split(',');
            vm.sequestrants = datavin.sequestrants.split(',');
            vm.activateursfermentation = datavin.activateursfermentation.split(',');
            vm.agentsclarifiants = datavin.agentsclarifiants.split(',');
            vm.agentsstabilisateurs = datavin.agentsstabilisateurs.split(',');
            vm.enzymes = datavin.enzymes.split(',');
            vm.checkboxatmoprotect = datavin.checkboxatmoprotect;
            vm.checkboxmaybeatmoprotect = datavin.checkboxmaybeatmoprotect;
            vm.gazetgazemballage = datavin.gazetgazemballage.split(',');
            vm.agentsfermentation = datavin.agentsfermentation.split(',');
            vm.correctiondefauts = datavin.correctiondefauts.split(',');
            vm.autrespratiques = datavin.autrespratiques.split(',');

            vm.photovin = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${this.uuidvin}&type=photovin&vn_Taille=256&cb=${Date.now()}`;
            vm.photoetiquetteface = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${this.uuidvin}&type=etiquetteface&vn_Taille=256&cb=${Date.now()}`;
            vm.photoetiquettedos = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${this.uuidvin}&type=etiquettedos&vn_Taille=256&cb=${Date.now()}`;
            vm.qrcode = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${this.uuidvin}&type=qrcode&vn_Taille=256&cb=${Date.now()}`;
        },
        modifiervin() {
            this.$router.push({
                path: `/modifiervin/${this.uuidvin}`
            });
        },
        async downloadimageandconvert(url) {
            const myurl = url;
            const toDataURL = await fetch(myurl)
                .then((response) => response.blob())
                .then((blob) => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }));
            return toDataURL;
        },
        async exporter() {
            const vm = this;
            vm.nomduvintxt = vm.nomduvin.replace(/[^a-z0-9àéèäâëê]/gi, '_').toLowerCase();
            const filename = `${vm.nomduvintxt} ${vm.numerolot} ${vm.millesime} ${vm.couleur}`;
            vm.photovindl = await vm.downloadimageandconvert(vm.photovin);
            const photoetiquetteface = await vm.downloadimageandconvert(vm.photoetiquetteface);
            const photoetiquettedos = await vm.downloadimageandconvert(vm.photoetiquettedos);
            const qrcode = await vm.downloadimageandconvert(vm.qrcode);
            const zip = new JSZip();

            const img = zip.folder('images');
            img.file('photovin.jpg', vm.photovindl.substr(vm.photovindl.indexOf(',') + 1), { base64: true });
            img.file('photoetiquette.jpg', photoetiquetteface.substr(photoetiquetteface.indexOf(',') + 1), { base64: true });
            img.file('photocontreetiquette.jpg', photoetiquettedos.substr(photoetiquettedos.indexOf(',') + 1), { base64: true });
            img.file('codeqr.png', qrcode.substr(qrcode.indexOf(',') + 1), { base64: true });

            // const arrtxt = [];
            if (vm.appellation !== '20202020202020202020202020202020') {
                const searchresponse = vm.rechercheappelationParUUID(vm.appellation);
                if (searchresponse !== -1) {
                    vm.appellationtxt = searchresponse;
                }
            }
            vm.cepagearrtxt = [];
            for (let i = 0; i < vm.cepage.length; i += 1) {
                const searchresponsecepage = vm.recherchecepageParUUID(vm.cepage[i]);
                if (searchresponsecepage !== -1) {
                    vm.cepagearrtxt.push(searchresponsecepage);
                }
            }

            function rechercheconditionnementParUUID(uuidRecherche) {
                const resultats = vm.arrconditionnement.filter((element) => element.UUID === uuidRecherche);
                return resultats.length > 0 ? resultats[0] : null;
            }

            vm.conditionnementarrtxt = [];
            for (let i = 0; i < vm.conditionnement.length; i += 1) {
                const result = rechercheconditionnementParUUID(vm.conditionnement[i]);
                if (result !== null) {
                    vm.conditionnementarrtxt.push(result.nom);
                }
            }

            const jsonData = [];
            jsonData.push(['Nom du vin', vm.nomduvin]);
            jsonData.push(['Numéro de lot', vm.numerolot]);
            jsonData.push(['Millésime', vm.millesime]);
            jsonData.push(['Appellation', vm.appellationtxt]);
            jsonData.push(['Catégorie de vin', vm.categorie]);
            jsonData.push(['Couleur', vm.couleur]);
            vm.conditionnementarrtxt.unshift('Conditionnement');
            jsonData.push(vm.conditionnementarrtxt);
            jsonData.push(['Titre alcoométrique volumique acquis (TAVA)', vm.tauxdalcool, '% vol.']);
            vm.cepagearrtxt.unshift('Cépages');
            jsonData.push(vm.cepagearrtxt);
            jsonData.push(['Déclaration nutritionnelle']);
            jsonData.push(['Valeur énergétique : ']);
            jsonData.push(['Valeurs pour 100 ml : ', `${vm.valenerkj} kJ / ${vm.valenerkcal} Kcal`]);
            jsonData.push(['Glucides', `${vm.glucides} g`]);
            jsonData.push(['dont sucre résiduel', `${vm.sucreresiduel} g`]);

            if (vm.checkboxfaiblequant === true) {
                jsonData.push(['Contient de faibles quantités de matières grasses, d\'acides gras saturés, de protéines et de sel.']);
            }

            vm.ingredientstxt = JSON.parse(JSON.stringify(vm.ingredients));
            if (vm.ingredientstxt === '') {
                vm.ingredientstxt = [];
            }
            vm.ingredientstxt.unshift('Les ingrédients');
            jsonData.push(vm.ingredientstxt);

            vm.liqueurtiragetxt = JSON.parse(JSON.stringify(vm.liqueurtirage));
            if (vm.liqueurtiragetxt === '') {
                vm.liqueurtiragetxt = [];
            }
            vm.liqueurtiragetxt.unshift('Liqueur de tirage');
            jsonData.push(vm.liqueurtiragetxt);

            vm.liqueurexpeditiontxt = JSON.parse(JSON.stringify(vm.liqueurexpedition));
            if (vm.liqueurexpeditiontxt === '') {
                vm.liqueurexpeditiontxt = [];
            }
            vm.liqueurexpeditiontxt.unshift('Liqueur d\'expédition');
            jsonData.push(vm.liqueurexpeditiontxt);

            function rechercheArrParNom(arrtosearch, recherche) {
                const resultats = arrtosearch.filter((element) => element.nom === recherche);
                return resultats.length > 0 ? resultats[0] : null;
            }

            vm.regulateursaciditetxt = [];
            for (let i = 0; i < vm.regulateursacidite.length; i += 1) {
                const nom = vm.regulateursacidite[i];
                if (vm.regulateursacidite[i].length > 0) {
                    const item = rechercheArrParNom(vm.arrregulateursacidite, nom);
                    vm.regulateursaciditetxt.push(vm.formatItemText(item));
                }
            }

            vm.regulateursaciditetxt.unshift('Régulateurs d\'acidité');
            jsonData.push(vm.regulateursaciditetxt);

            vm.conservateuretantioxydantstxt = [];
            for (let i = 0; i < vm.conservateuretantioxydants.length; i += 1) {
                if (vm.conservateuretantioxydants[i].length > 0) {
                    const item = rechercheArrParNom(vm.arrconservateuretantioxydants, vm.conservateuretantioxydants[i]);
                    vm.conservateuretantioxydantstxt.push(vm.formatItemText(item));
                }
            }

            vm.conservateuretantioxydantstxt.unshift('Conservateurs et antioxydants');
            jsonData.push(vm.conservateuretantioxydantstxt);

            vm.sequestrantstxt = JSON.parse(JSON.stringify(vm.sequestrants));
            if (vm.sequestrantstxt === '') {
                vm.sequestrantstxt = [];
            }
            vm.sequestrantstxt.unshift('Séquestrants');
            jsonData.push(vm.sequestrantstxt);

            vm.activateursfermentationtxt = [];
            for (let i = 0; i < vm.activateursfermentation.length; i += 1) {
                if (vm.activateursfermentation[i].length > 0) {
                    const item = rechercheArrParNom(vm.arractivateursfermentation, vm.activateursfermentation[i]);
                    vm.activateursfermentationtxt.push(vm.formatItemText(item));
                }
            }

            vm.activateursfermentationtxt.unshift('Activateurs de fermentation alcoolique et malolactique');
            jsonData.push(vm.activateursfermentationtxt);

            vm.agentsclarifiantstxt = [];
            for (let i = 0; i < vm.agentsclarifiants.length; i += 1) {
                if (vm.agentsclarifiants[i].length > 0) {
                    const item = rechercheArrParNom(vm.arragentsclarifiants, vm.agentsclarifiants[i]);
                    vm.agentsclarifiantstxt.push(vm.formatItemText(item));
                }
            }

            vm.agentsclarifiantstxt.unshift('Agents clarifiants');
            jsonData.push(vm.agentsclarifiantstxt);

            vm.agentsstabilisateurstxt = [];
            for (let i = 0; i < vm.agentsstabilisateurs.length; i += 1) {
                if (vm.agentsstabilisateurs[i].length > 0) {
                    const item = rechercheArrParNom(vm.arragentsstabilisateurs, vm.agentsstabilisateurs[i]);
                    vm.agentsstabilisateurstxt.push(vm.formatItemText(item));
                }
            }

            vm.agentsstabilisateurstxt.unshift('Agents stabilisateurs');
            jsonData.push(vm.agentsstabilisateurstxt);

            vm.enzymestxt = [];
            for (let i = 0; i < vm.enzymes.length; i += 1) {
                if (vm.enzymes[i].length > 0) {
                    const item = rechercheArrParNom(vm.arrenzymes, vm.enzymes[i]);
                    vm.enzymestxt.push(vm.formatItemText(item));
                }
            }

            vm.enzymestxt.unshift('Enzymes');
            jsonData.push(vm.enzymestxt);

            if (vm.checkboxatmoprotect === true) {
                jsonData.push(['Mis en bouteille sous atmosphère protectrice']);
            }

            if (vm.checkboxmaybeatmoprotect === true) {
                jsonData.push(['Peut être mis en bouteille sous atmosphère protectrice']);
            }

            vm.gazetgazemballagetxt = [];
            for (let i = 0; i < vm.gazetgazemballage.length; i += 1) {
                if (vm.gazetgazemballage[i].length > 0) {
                    const item = rechercheArrParNom(vm.arrgazetgazemballage, vm.gazetgazemballage[i]);
                    vm.gazetgazemballagetxt.push(vm.formatItemText(item));
                }
            }

            vm.gazetgazemballagetxt.unshift('Gaz et gaz d\'emballage');
            jsonData.push(vm.gazetgazemballagetxt);

            vm.agentsfermentationtxt = JSON.parse(JSON.stringify(vm.agentsfermentation));
            if (vm.agentsfermentationtxt === '') {
                vm.agentsfermentationtxt = [];
            }
            vm.agentsfermentationtxt.unshift('Agents de fermentation');
            jsonData.push(vm.agentsfermentationtxt);

            vm.correctiondefautstxt = JSON.parse(JSON.stringify(vm.correctiondefauts));
            if (vm.correctiondefautstxt === '') {
                vm.correctiondefautstxt = [];
            }
            vm.correctiondefautstxt.unshift('Correction des défauts');
            jsonData.push(vm.correctiondefautstxt);

            vm.autrespratiquestxt = JSON.parse(JSON.stringify(vm.autrespratiques));
            if (vm.autrespratiquestxt === '') {
                vm.autrespratiquestxt = [];
            }
            vm.autrespratiquestxt.unshift('Autres pratiques');
            jsonData.push(vm.autrespratiquestxt);

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(jsonData);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

            zip.file(`${filename}.xlsx`, wbout, { binary: true });
            zip.generateAsync({ type: 'blob' })
                .then((content) => {
                    saveAs(content, `${filename}.zip`);
                });
        }
    }
};
</script>
<style>
.v-input__icon {
    display: none;
}

.v-menu {
    display: none;
}
</style>
